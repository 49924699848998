<template>
  <div class="time-line">
    <template v-for="(item, index) in data">
      <div class="date__time" :key="'dateTime1' + index">
        <p class="date">{{ item.createtime.split(' ')[0] }}</p>
        <p class="time">{{ item.createtime.split(' ')[1] }}</p>
      </div>
      <div class="dit-solid" :key="'dit1' + index">
        <div class="round"></div>
        <div class="line"></div>
      </div>
      <div class="content" :key="'content1' + index">
        <div class="images" v-if="item.images.length > 0">
          <img class="img" :src="img.url" v-for="(img, imgI) in item.images" :key="imgI" alt="" />
        </div>
        <p class="describe" v-if="item.remark">{{ item.remark }}</p>
        <p class="describe" v-if="item.tempo">{{ item.tempo }}</p>
      </div>
      <template v-if="item.children">
        <template v-for="(sonItem, sonIndex) in item.children">
          <div class="date__time" :key="'dateTime2' + sonIndex">
            <template v-if="sonItem.time">
              <p class="date">{{ sonItem.time[0] }}</p>
              <p class="time">{{ sonItem.time[1] }}</p>
            </template>
          </div>
          <div class="dit-empty" :key="'dit2' + sonIndex">
            <div class="round"></div>
            <div class="line"></div>
          </div>
          <div class="content" :key="'content2' + sonIndex">
            <div class="images" v-if="sonItem.images">
              <img class="img" :src="img" v-for="img in sonItem.images" :key="img" alt="" />
            </div>
            <p class="describe" v-if="sonItem.describe">{{ sonItem.describe }}</p>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.time-line {
  display: grid;
  grid-template-columns: 150px 75px auto;
  .date__time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .date {
      font-size: 0.94rem;
      font-weight: 400;
      color: #000000;
      line-height: 1;
    }
    .time {
      padding-top: 10px;
      font-size: 0.83rem;
      font-weight: 400;
      color: #878787;
      line-height: 1;
    }
  }
  .dit-solid {
    box-sizing: content-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .round {
      flex-shrink: 0;
      display: block;
      width: 21px;
      height: 21px;
      background: linear-gradient(215deg, #1db8ab, #179d92);
      border-radius: 50%;
    }
    .line {
      display: block;
      height: 100%;
      width: 3px;
      background: linear-gradient(215deg, #1db8ab, #179d92);
    }
  }
  .dit-empty {
    box-sizing: content-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .round {
      flex-shrink: 0;
      display: block;
      width: 21px;
      height: 21px;
      border: 1px solid #cccccc;
      border-radius: 50%;
    }
    .line {
      display: block;
      height: 100%;
      width: 3px;
      background: #d4d4d4;
    }
  }
  .content {
    padding-bottom: 35px;
    .images {
      display: flex;
      align-items: flex-start;
      .img {
        width: 50px;
        margin: 0 8px;
        object-fit: contain;
      }
    }
    .describe {
      font-size: 0.83rem;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
    }
  }
}
</style>
