import request from './request'

export function getClientList(data) {
  return request({
    url: '/order.crmorder/listPage',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

export function addClient(data) {
  return request({
    url: '/order.crmorder/add',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

export function updateClient(data) {
  return request({
    url: '/order.crmorder/save',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

export function getFind(data) {
  return request({
    url: '/order.order/find',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 查询 客户 跟进/进度 信息
export function findOrderInfo(data) {
  return request({
    url: '/order.order/findorderinfo',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 添加客户 跟进/进度 信息
export function addOrderInfo(data) {
  return request({
    url: '/order.order/addorderinfo',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 转为成交客户(业务)
export function toComplete(data) {
  return request({
    url: '/order.crmorder/ywbargain',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 添加 客户 录入方案
export function addScheme(data) {
  return request({
    url: '/order.order/addscheme',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}

// 公海池  转移
export function orderGiveYwUser(data) {
  return request({
    url: '/order.order/orderGiveYwUser',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
