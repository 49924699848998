<template>
  <div class="client-page view-page">
    <c-search searchTitle="模糊查询" @search="clientSearch" placeholder="姓名/电话" :isDate="false"></c-search>
    <c-navigation v-if="isCommon == false" @change="changeClientCategory" title="客户类别" :list="clientCategory" :isBorder="true"></c-navigation>
    <div class="flex" style="border-bottom: 1px solid #edf2f2">
      <c-navigation v-if="clientTypeList.length > 0" @change="changeClientClassify" title="客户分类" :list="clientTypeList"></c-navigation>
      <c-navigation @change="changeFollowUpSituation" title="跟进情况" :list="followList"></c-navigation>
    </div>
    <c-navigation v-if="approvalList.length > 0" @change="changeNavigation" title="案件情况" :list="approvalList" :isBorder="true"></c-navigation>

    <!-- 按钮 -->
    <c-operate>
      <template #left>
        <div class="btn-group">
          <div class="btn-list deal" v-if="isCommon == false" @click="highSeasPool(1)">进入公海池</div>
          <div class="btn-list deal" v-else @click="myClient(2)">返回我的客户</div>
        </div>
      </template>
      <template #right v-if="isCommon == false">
        <div class="btn-group">
          <div class="btn-list deal" @click="openToComplete">成交客户</div>
          <!-- <div class="btn-list export" @click="showDialog = true">导出</div> -->
          <div class="btn-list new" @click="openNewClient">新建客户</div>
        </div>
      </template>
    </c-operate>

    <!-- 表格 -->
    <!-- 我的客户 -->
    <c-table :data="tableData1" v-if="isCommon == false">
      <el-table-column align="center" label="选择">
        <template #default="{ row }">
          <el-checkbox @change="myClientCheckbox(row)"></el-checkbox>
          {{ row.id }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="username" label="联系人" width="80"></el-table-column>
      <el-table-column align="center" prop="mobile" label="电话" width="130"></el-table-column>
      <el-table-column align="center" prop="aj_name" label="案件名称"></el-table-column>
      <el-table-column align="center" prop="age" label="年龄"></el-table-column>
      <el-table-column align="center" prop="sexlist" label="性别"></el-table-column>
      <el-table-column align="center" prop="company" label="公司"></el-table-column>
      <el-table-column align="center" prop="typelist" label="客户分类"></el-table-column>
      <el-table-column align="center" prop="aj_ms_statuslist" label="案件情况"></el-table-column>
      <el-table-column align="center" prop="sourcelist" label="客户来源"></el-table-column>
      <el-table-column align="center" prop="yw_username" label="业务人员"></el-table-column>
      <el-table-column align="center" prop="prestatus" label="是否成交">
        <template #default="{ row }">
          <p>{{ ['加微信', '来访', '已成交', '未付款'][row.prestatus] }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间" width="180"></el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list edit" @click="edit(row)">编辑</p>
            <p class="operate-list follow" @click="findOrderInfo(row.id)">跟进信息</p>
          </div>
        </template>
      </el-table-column>
    </c-table>

    <!-- 公海池 -->
    <c-table :data="tableData1" v-else>
      <!-- <el-table-column align="center" label="选择">
        <template #default="{ row }">
          <el-checkbox></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="username" label="联系人" width="80"></el-table-column>
      <el-table-column align="center" prop="mobile" label="电话" width="130"></el-table-column>
      <el-table-column align="center" prop="aj_name" label="案件名称"></el-table-column>
      <el-table-column align="center" prop="age" label="年龄"></el-table-column>
      <el-table-column align="center" prop="sexlist" label="性别"></el-table-column>
      <el-table-column align="center" prop="company" label="公司"></el-table-column>
      <el-table-column align="center" prop="typelist" label="客户分类"></el-table-column>
      <el-table-column align="center" prop="aj_ms_statuslist" label="案件情况"></el-table-column>
      <el-table-column align="center" prop="sourcelist" label="客户来源"></el-table-column>
      <el-table-column align="center" prop="yw_username" label="业务人员"></el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间" width="180"></el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template #default="{ row }">
          <p class="transfer" @click="setOrderGiveYwUser(row)">转移</p>
        </template>
      </el-table-column>
    </c-table>

    <!-- 成交客户 -->
    <c-drawer v-model="showMakeABargain" :width="35" title="成交客户信息">
      <div class="ACustomer">
        <p class="title">上传合同</p>
        <div class="newClientFrom newClientFromPadding">
          <c-upload @on-success="changeImg" @on-remove="changeImg" :isDocument="false" multiple :limit="6"></c-upload>
        </div>
        <el-form ref="form" class="form" :model="toCompleteParam" :rules="rules" label-width="140px">
          <el-form-item label="成交金额：" class="form-list" prop="money">
            <el-input v-model="toCompleteParam.money" size="mini" placeholder="请输入内容">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注信息：" class="form-list" prop="remark">
            <el-input class="list-value" v-model="toCompleteParam.remark" type="textarea" :rows="3" resize="none" placeholder="请输入内容"> </el-input>
          </el-form-item>
          <p class="title">#开票信息</p>
          <el-form-item label="公司全称：" class="form-list" prop="company">
            <el-input class="list-value" v-model="toCompleteParam.company" size="mini" placeholder="请输入内容"> </el-input>
          </el-form-item>
          <el-form-item label="纳税人识别号：" class="form-list" prop="number">
            <el-input class="inp" v-model="toCompleteParam.number" size="mini" placeholder="请输入内容"> </el-input>
          </el-form-item>
          <el-form-item label="单位地址：" class="form-list" prop="addr">
            <el-input class="inp" v-model="toCompleteParam.addr" size="mini" placeholder="请输入内容"> </el-input>
          </el-form-item>
          <el-form-item label="电话：" class="form-list" prop="mobile">
            <el-input class="inp" v-model="toCompleteParam.mobile" size="mini" placeholder="请输入内容"> </el-input>
          </el-form-item>
          <el-form-item label="开户行：" class="form-list" prop="bank">
            <el-input class="inp" v-model="toCompleteParam.bank" size="mini" placeholder="请输入内容"> </el-input>
          </el-form-item>
          <el-form-item label="银行账号：" class="form-list" prop="bankname">
            <el-input class="inp" v-model="toCompleteParam.bankname" size="mini" placeholder="请输入内容"> </el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <div class="cancel" @click="$emit('cancel')">取消</div>
          <div class="confirm" @click="submitToComplete">确定</div>
        </div>
      </div>
    </c-drawer>
    <!-- 客户信息 -->
    <c-drawer v-model="showNewClient" :width="35" title="客户信息">
      <div class="newClient">
        <el-form ref="newForm" class="form" :model="addEditParam" :rules="newRules" label-width="110px">
          <p class="title">#客户信息</p>
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名：" class="form-list" prop="username">
                <el-input class="list-value" v-model="addEditParam.username" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式：" class="form-list" prop="mobile">
                <el-input class="list-value" v-model="addEditParam.mobile" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="年龄：" class="form-list" prop="age">
                <el-input class="list-value" v-model="addEditParam.age" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别：" class="form-list">
                <el-select class="list-value" v-model="addEditParam.sexlist" size="mini" placeholder="请选择">
                  <el-option v-for="item in sex" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="客户分类：" class="form-list">
                <el-select class="list-value" v-model="addEditParam.typelist" size="mini" placeholder="请选择">
                  <el-option v-for="item in clientTypeList.slice(1, clientTypeList.length)" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="案件状态：" class="form-list">
                <el-select class="list-value" v-model="addEditParam.prestatus" size="mini" placeholder="请选择">
                  <el-option v-for="item in addCaseType" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起诉/应诉：" class="form-list">
                <el-select class="list-value" v-model="addEditParam.lawsuitlist" size="mini" placeholder="请选择">
                  <el-option v-for="item in lawsuitList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="客户来源：" class="form-list">
                <el-select class="list-value" v-model="addEditParam.sourcelist" size="mini" placeholder="请选择">
                  <el-option v-for="item in sourceList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="客户地址：" class="form-list" prop="addr">
                <el-input class="list-value" v-model="addEditParam.addr" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="公司名称：" class="form-list">
                <el-input class="list-value" v-model="addEditParam.company" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="备注：" class="form-list">
                <el-input class="list-value" v-model="addEditParam.renark" type="textarea" :rows="3" resize="none" placeholder="请输入内容"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p class="title">#案件信息</p>
          <el-row>
            <el-col :span="12">
              <el-form-item label="案件类型：" class="form-list">
                <el-select class="list-value" v-model="addEditParam.aj_statuslist" size="mini" placeholder="请选择">
                  <el-option v-for="item in clientType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标的额：" class="form-list">
                <el-input class="list-value" v-model="addEditParam.aj_money" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="案件情况：" class="form-list">
                <el-select class="list-value" v-model="civilParam.aj_ms_statuslist" size="mini" placeholder="请选择">
                  <el-option v-for="item in approvalList.slice(1, approvalList.length)" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="案件类型：" class="form-list">
                <el-select class="list-value" v-model="civilParam.aj_ms_typelist" size="mini" placeholder="请选择">
                  <el-option v-for="item in aj_ms_typeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="addEditParam.aj_statuslist == 1">
            <el-row>
              <el-col :span="12">
                <el-form-item label="案件阶段：" class="form-list">
                  <el-select class="inp" v-model="criminalParam.aj_xs_statuslist" size="mini" placeholder="请选择">
                    <el-option v-for="item in aj_xs_statusList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="罪名：" class="form-list">
                  <el-select class="inp" v-model="criminalParam.aj_xs_contentlist" size="mini" placeholder="请选择">
                    <el-option v-for="item in aj_xs_contentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="18">
                <el-form-item label="委托人与被害人关系：" class="form-list">
                  <el-select class="inp" v-model="criminalParam.aj_xs_tylelist" size="mini" placeholder="请选择">
                    <el-option v-for="item in aj_xs_tyleList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <el-row v-if="addEditParam.aj_statuslist == 0">
            <el-col :span="18">
              <el-form-item label="案由：" class="form-list">
                <el-select class="inp" v-model="civilParam.aj_ms_contentlist" size="mini" placeholder="请选择">
                  <el-option v-for="item in aj_ms_contentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="案号：" class="form-list">
                <el-input class="inp" v-model="addEditParam.aj_code" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="案发时间：" class="form-list">
                <el-date-picker class="inp" size="mini" value-format="yyyy-MM-dd" v-model="addEditParam.aj_date" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="备注：" class="form-list">
                <el-input class="inp" type="textarea" :rows="3" resize="none" placeholder="请输入内容" v-model="addEditParam.aj_remark"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p class="title">#起诉</p>
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名：" class="form-list">
                <el-input class="inp" v-model="addEditParam.yg_name" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄：" class="form-list">
                <el-input class="inp" v-model="addEditParam.yg_age" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系方式：" class="form-list">
                <el-input class="inp" v-model="addEditParam.yg_mobile" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别：" class="form-list">
                <el-select class="inp" v-model="addEditParam.yg_sex" size="mini" placeholder="请选择">
                  <el-option v-for="item in sex" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="身份证号：" class="form-list">
                <el-input class="inp" v-model="addEditParam.yg_idnum" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="地区：" class="form-list">
                <el-input class="inp" v-model="addEditParam.yg_area" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="地址：" class="form-list">
                <el-input class="inp" v-model="addEditParam.yg_addr" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="备注：" class="form-list">
                <el-input class="inp" type="textarea" :rows="3" resize="none" placeholder="请输入内容" v-model="addEditParam.yg_remark"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p class="title">#应诉</p>
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名：" class="form-list">
                <el-input class="inp" v-model="addEditParam.bg_name" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄：" class="form-list">
                <el-input class="inp" v-model="addEditParam.bg_age" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系方式：" class="form-list">
                <el-input class="inp" v-model="addEditParam.bg_mobile" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别：" class="form-list">
                <el-select class="inp" v-model="addEditParam.bg_sex" size="mini" placeholder="请选择">
                  <el-option v-for="item in sex" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="身份证号：" class="form-list">
                <el-input class="inp" v-model="addEditParam.bg_idnum" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="地区：" class="form-list">
                <el-input class="inp" v-model="addEditParam.bg_area" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="地址：" class="form-list">
                <el-input class="inp" v-model="addEditParam.bg_addr" size="mini" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="备注：" class="form-list">
                <el-input class="inp" type="textarea" :rows="3" resize="none" placeholder="请输入内容" v-model="addEditParam.bg_remark"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="btn-box">
          <div class="btn" @click="addClient">保存</div>
        </div>
      </div>
    </c-drawer>
    <!-- 跟进详情 -->
    <c-drawer v-model="showDetails" :width="35" title="跟进信息详情">
      <c-time-line :data="timeLine"></c-time-line>
      <div class="addTimeLine-box">
        <div class="addTimeLine" @click="showNewDetails = true">添加跟进</div>
      </div>
    </c-drawer>
    <!-- 新建跟进 -->
    <c-dialog v-model="showNewDetails" title="新建跟进" :width="35" :mask="false" @confirm="newDetailsConfirm">
      <div style="display: flex">
        <p class="name" style="width: 60px">备注：</p>
        <el-input class="inp" v-model="addFollowupParam.remark" type="textarea" :rows="3" resize="none" placeholder="请输入内容"> </el-input>
      </div>
      <c-upload @on-success="changeNewDetailsImgs" @on-remove="changeNewDetailsImgs" :isDocument="false" multiple :limit="6"></c-upload>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="requestListParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDrawer from '@/components/c-drawer'
import CDialog from '@/components/c-dialog'
import CTimeLine from '@/components/c-time-line'
import * as clientApi from '@/api/client.js'
import { getTypeList } from '@/api/common'
import deepClone from '@/utils/clone.js'
import config from "@/utils/config.js";
const addEditParamBase = {
  // 客户信息
  username: '',
  sexlist: 0,
  age: '',
  mobile: '',
  typelist: 0,
  lawsuitlist: 0,
  sourcelist: 0,
  prestatus: 0,
  addr: '',
  company: '',
  renark: '',
  // 案件信息
  aj_statuslist: 0,
  aj_money: '',
  aj_code: '',
  aj_date: '',
  aj_remark: '',
  // 原告
  yg_name: '',
  yg_age: '',
  yg_sex: 0,
  yg_mobile: '',
  yg_idnum: '',
  yg_area: '',
  yg_addr: '',
  yg_remark: '',
  // 被告
  bg_name: '',
  bg_age: '',
  bg_sex: 0,
  bg_mobile: '',
  bg_idnum: '',
  bg_area: '',
  bg_addr: '',
  bg_remark: ''
}
const civilParamBase = {
  aj_ms_statuslist: 0,
  aj_ms_typelist: 0,
  aj_ms_contentlist: 0
}
const criminalParamBase = {
  aj_xs_statuslist: 0,
  aj_xs_tylelist: 0,
  aj_xs_contentlist: 0
}
export default {
  name: 'Client',
  components: {
    CDrawer,
    CDialog,
    CTimeLine
  },
  data() {
    this.sex = [
      {
        value: 0,
        label: '男'
      },
      {
        value: 1,
        label: '女'
      }
    ]
    this.clientType = [
      {
        value: 0,
        label: '民事'
      },
      {
        value: 1,
        label: '刑事'
      }
    ]
    return {
      rules: {
        money: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        company: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        number: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        addr: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        bank: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        bankname: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      newRules: {
        username: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        age: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        addr: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      clientCategory: [
        {
          id: 2,
          name: '跟进客户',
          isChecked: true
        },
        {
          id: 3,
          name: '成交客户',
          isChecked: false
        }
      ],
      contactsTotal: 0,

      // 跟进情况
      followList: [
        {
          id: 0,
          isChecked: true,
          name: '加微信'
        },
        {
          id: 1,
          isChecked: false,
          name: '来访'
        },
        {
          id: 2,
          isChecked: false,
          name: '已成交'
        }
      ],
      // 数据的参数
      requestListParam: {
        usmo: '', // 搜索的关键词
        type: 2, // 1:公海池 2:跟进客户 3:成交客户
        prestatus: 0, //跟进情况:0=加微信,1:来访 2:已成交
        typelist: '', //	 客户分类:0=A类,1=B类,2=C类,3=D类
        aj_ms_statuslist: '', // 民事 案件情况:0=未诉,1=财产保全,2=一审,3=二审,4=执行,5=终本,6=执恢,7=破产清算
        page: 1, // 分页页码，数字类型
        limit: 7 // 一页展示条数
      },
      isCommon: false, //是否显示公海池
      showMakeABargain: false, //成交客户
      // 客户分类
      clientTypeList: [],
      // 分类
      approvalList: [],
      tableData1: [],
      // tableData2: [
      //   {
      //     date: 1
      //   }
      // ],
      showNewClient: false,
      newClientFrom: {}, //成交客户信息
      // 新建跟进
      showNewDetails: false,
      // 跟进详情
      input: '1',
      showDetails: false,
      timeLine: [
        {
          time: ['2022-01-10', '11:11:11'],
          describe: 'describe',
          images: [
            'https://img1.baidu.com/it/u=3782484606,1502060984&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
            'https://img0.baidu.com/it/u=1675910634,266519000&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750',
            'https://img1.baidu.com/it/u=3782484606,1502060984&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500'
          ],
          children: [{ describe: 'describe' }, { describe: 'describe' }, { describe: 'describe' }]
        },
        {
          time: ['2022-01-10', '11:11:11'],
          describe: 'describe',
          images: ['https://img1.baidu.com/it/u=3782484606,1502060984&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500', 'https://img0.baidu.com/it/u=1675910634,266519000&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750'],
          children: [{ describe: 'describe' }]
        },
        {
          time: ['2022-01-10', '11:11:11'],
          describe: 'describe',
          images: ['https://img0.baidu.com/it/u=1675910634,266519000&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750', 'https://img1.baidu.com/it/u=3782484606,1502060984&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500'],
          children: [{ describe: 'describe' }]
        },
        {
          time: ['2022-01-10', '11:11:11'],
          describe: 'describe',
          images: [
            'https://img1.baidu.com/it/u=3782484606,1502060984&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
            'https://img0.baidu.com/it/u=1675910634,266519000&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750',
            'https://img0.baidu.com/it/u=1675910634,266519000&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750'
          ],
          children: [{ describe: 'describe' }]
        }
      ],
      // 假数据
      options: [],
      value: '',
      isAddOrEdit: 0, // 0: 添加 1: 编辑
      addEditParam: {},
      addCaseType: [
        {
          id: 0,
          name: '加微信'
        },
        {
          id: 1,
          name: '来访'
        },
        {
          id: 2,
          name: '已成交'
        },
        {
          id: 3,
          name: '未支付'
        }
      ],
      civilParam: {},
      criminalParam: {},
      // 应诉/起诉
      lawsuitList: [],
      // 客户来源
      sourceList: [],
      // 民事案件情况
      aj_ms_statusList: [],
      // 民事案件类型
      aj_ms_typeList: [],
      // 民事案由
      aj_ms_contentList: [],
      // 刑事案件阶段
      aj_xs_statusList: [],
      // 刑事案件类型
      aj_xs_tyleList: [],
      // 刑事案件罪名
      aj_xs_contentList: [],
      // 转为成交客户的参数
      toCompleteParam: {
        order_id: '',
        money: '',
        remark: '',
        company: '',
        number: '',
        addr: '',
        mobile: '',
        bankname: '',
        bank: '',
        images: ''
      },
      toCompleteImages: [],
      myClientCheckboxArray: [],
      editMapParam: {
        sexlist: {}, // sex
        yg_sex: {}, // sex
        bg_sex: {}, // sex
        typelist: {}, // clientTypeList
        lawsuitlist: {}, // lawsuitList
        sourcelist: {}, // sourceList
        aj_statuslist: {}, // clientType
        aj_ms_statuslist: {}, // aj_ms_statusList
        aj_ms_typelist: {}, // aj_ms_typeList
        aj_ms_contentlist: {}, // aj_ms_contentList
        aj_xs_statuslist: {}, // aj_xs_statusList
        aj_xs_tylelist: {}, // aj_xs_tyleList
        aj_xs_contentlist: {} // aj_xs_contentList
      },
      addFollowupParam: {
        status: 0,
        order_id: '',
        images: '',
        remark: '',
        tempo: ''
      },
      addFollowupImages: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.resetAddOrEditParam()
      this.clientTypeList = await getTypeList(1)
      const getTypeList2 = await getTypeList(2)
      this.lawsuitList = getTypeList2.slice(1)
      const getTypeList3 = await getTypeList(3)
      this.sourceList = getTypeList3.slice(1)
      const getTypeList4 = await getTypeList(4)
      this.aj_ms_statusList = getTypeList4.slice(1)
      this.approvalList = await getTypeList(5)
      const getTypeList6 = await getTypeList(6)
      this.aj_ms_typeList = getTypeList6.slice(1)
      const getTypeList7 = await getTypeList(7)
      this.aj_ms_contentList = getTypeList7.slice(1)
      const getTypeList8 = await getTypeList(8)
      this.aj_xs_statusList = getTypeList8.slice(1)
      const getTypeList9 = await getTypeList(9)
      this.aj_xs_tyleList = getTypeList9.slice(1)
      const getTypeList10 = await getTypeList(10)
      this.aj_xs_contentList = getTypeList10.slice(1)
      this.getClient()
      this.setEditParamMap()
    },
    /**
     * @description: 设置编辑参数的对应关系
     */
    setEditParamMap() {
      this.sex.forEach(i => {
        this.editMapParam.sexlist[i.label] = i.value
      })
      this.editMapParam.yg_sex = this.editMapParam.sexlist
      this.editMapParam.bg_sex = this.editMapParam.sexlist
      this.clientTypeList.forEach(i => {
        this.editMapParam.typelist[i.name] = i.id
      })
      this.lawsuitList.forEach(i => {
        this.editMapParam.lawsuitlist[i.name] = i.id
      })
      this.sourceList.forEach(i => {
        this.editMapParam.sourcelist[i.name] = i.id
      })
      this.clientType.forEach(i => {
        this.editMapParam.aj_statuslist[i.label] = i.value
      })
      this.aj_ms_statusList.forEach(i => {
        this.editMapParam.aj_ms_statuslist[i.name] = i.id
      })
      this.aj_ms_typeList.forEach(i => {
        this.editMapParam.aj_ms_typelist[i.name] = i.id
      })
      this.aj_ms_contentList.forEach(i => {
        this.editMapParam.aj_ms_contentlist[i.name] = i.id
      })
      this.aj_xs_statusList.forEach(i => {
        this.editMapParam.aj_xs_statuslist[i.name] = i.id
      })
      this.aj_xs_tyleList.forEach(i => {
        this.editMapParam.aj_xs_tylelist[i.name] = i.id
      })
      this.aj_xs_contentList.forEach(i => {
        this.editMapParam.aj_xs_contentlist[i.name] = i.id
      })
    },
    /**
     * @description: 重置添加或者编辑的参数
     */
    resetAddOrEditParam() {
      this.addEditParam = deepClone(addEditParamBase)
      console.log('[ 🎯 this.addEditParam ]', this.addEditParam)
      this.civilParam = deepClone(civilParamBase)
      this.criminalParam = deepClone(criminalParamBase)
    },
    /**
     * @description: 查询客户跟进/进度信息
     * @param {*} order_id: 要查询的 ID
     */
    findOrderInfo(order_id) {
      this.addFollowupParam.order_id = order_id
      this.showDetails = true
      const param = {
        type: '0',
        order_id
      }
      clientApi.findOrderInfo(param).then(res => {
        this.timeLine = res.data
      })
    },
    /**
     * @description: 客户查询
     * @param {*} e: 要查询的条件
     */
    clientSearch(e) {
      this.requestListParam.usmo = e.keyWord
      this.getClient()
    },
    /**
     * @description: 切换案件情况
     * @param {*} e: 切换后的值
     */
    changeNavigation(e) {
      this.requestListParam.aj_ms_statuslist = e.id
      this.getClient()
    },
    /**
     * @description: 公海池
     */
    highSeasPool(id) {
      this.isCommon = true
      this.requestListParam.type = id
      this.requestListParam.usmo = ''
      this.getClient()
    },
    /**
     * @description: 我的客户
     */
    myClient(id) {
      this.isCommon = false
      this.requestListParam.type = id
      this.requestListParam.usmo = ''
      this.getClient()
    },
    /**
     * @description: 切换跟进情况
     * @param {Object} e: 切换后的值
     */
    changeFollowUpSituation(e) {
      this.requestListParam.prestatus = e.id
      this.getClient()
    },
    /**
     * @description: 切换客户分类
     * @param {Object} e: 切换后的值
     */
    changeClientClassify(e) {
      this.requestListParam.typelist = e.id
      this.getClient()
    },
    /**
     * @description: 切换客户类别
     * @param {Object} e: 切换后的值
     */
    changeClientCategory(e) {
      this.requestListParam.type = e.id
      this.getClient()
    },
    /**
     * @description: 打开新建客户
     */
    openNewClient() {
      this.showNewClient = true
      this.isAddOrEdit = 0
      this.resetAddOrEditParam()
    },
    /**
     * @description: 添加客户
     */
    addClient() {
      let param = this.addEditParam
      if (param.aj_statuslist == 0) {
        param = Object.assign(param, this.civilParam)
      } else if (param.aj_statuslist == 1) {
        param = Object.assign(param, this.criminalParam)
      }
      if (this.isAddOrEdit == 0) {
        this.$refs.newForm.validate(valid => {
          if (valid) {
            clientApi.addClient(param).then(res => {
              this.showNewClient = false
              this.getClient()
              this._showMsg('添加成功', 'success')
            })
          }
        })
      } else if (this.isAddOrEdit == 1) {
        clientApi.updateClient(param).then(res => {
          this.showNewClient = false
          this.getClient()
          this._showMsg('编辑成功', 'success')
        })
      }
    },
    /**
     * @description: 编辑
     * @param {*} row: 要编辑的数据
     */
    edit(row) {
      this.isAddOrEdit = 1
      this.resetAddOrEditParam()
      clientApi.getFind({ id: row.id }).then(res => {
        const data = res.data
        this.addEditParam.id = row.id
        for (const i in this.addEditParam) {
          this.addEditParam[i] = data[i]
          if (this.editMapParam[i]) {
            this.addEditParam[i] = this.editMapParam[i][data[i]]
          }
          if (i == 'aj_statuslist') {
            this.addEditParam.aj_statuslist = Number(data.aj_statuslist_z)
          }
          if (i == 'prestatus') {
            this.addEditParam.prestatus = Number(data.prestatus)
          }
        }
        for (const i in this.civilParam) {
          this.civilParam[i] = data[i]
          if (this.editMapParam[i]) {
            this.civilParam[i] = this.editMapParam[i][data[i]]
          }
        }
        for (const i in this.criminalParam) {
          this.criminalParam[i] = data[i]
          if (this.editMapParam[i]) {
            this.criminalParam[i] = this.editMapParam[i][data[i]]
          }
        }
        this.showNewClient = true
      })
    },

    /**
     * 提交数据
     */
    submit() {},
    /**
     * @description: 获取数据列表
     */
    getClient() {
      clientApi.getClientList(this.requestListParam).then(res => {
        this.tableData1 = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * @description: 改变我的客户列表选中的值
     * @param {*} v: 改变的值
     */
    myClientCheckbox(v) {
      const i = this.myClientCheckboxArray.indexOf(v.id)
      if (i !== -1) {
        this.myClientCheckboxArray.splice(i, 1)
      } else {
        this.myClientCheckboxArray.push(v.id)
      }
    },
    /**
     * @description: 改变图片列表
     * @param {*} file: 图片的列表数据
     */
    changeImg(file) {
      this.toCompleteImages = file
    },
    /**
     * @description: 修改跟进图片的列表
     * @param {*} file: 图片的列表数据
     */
    changeNewDetailsImgs(file) {
      this.addFollowupImages = file
    },
    /**
     * @description: 打开转为成交客户的弹出层
     */
    openToComplete() {
      if (this.myClientCheckboxArray.length == 1) {
        this.showMakeABargain = true
      } else if (this.myClientCheckboxArray.length < 1) {
        this._showMsg('请选择一个客户')
      } else {
        this._showMsg('仅能选择一个客户')
      }
    },
    /**
     * @description: 确定转为成交客户
     */
    submitToComplete() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.toCompleteParam.order_id = this.myClientCheckboxArray[0]
          const imgs = []
          this.addFollowupImages.forEach(i => {
            const url = i.url.replace(config.baseUrl, '')
            imgs.push(url)
          })
          this.toCompleteParam.images = imgs.join(',')
          clientApi.toComplete(this.toCompleteParam).then(res => {
            this._showMsg('添加成功', 'success')
            this.getClient()
            this.showMakeABargain = false
          })
        }
      })
    },
    /**
     * @description: 新建跟进的确认
     */
    newDetailsConfirm() {
      if (!this.addFollowupParam.remark) return this._showMsg('请输入备注')
      const imgs = []
      this.addFollowupImages.forEach(i => {
        const url = i.url.replace(config.baseUrl, '')
        imgs.push(url)
      })
      this.addFollowupParam.images = imgs.join(',')
      clientApi.addOrderInfo(this.addFollowupParam).then(res => {
        this._showMsg('新建成功', 'success')
        this.showNewDetails = false
        this.findOrderInfo(this.addFollowupParam.order_id)
      })
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.requestListParam.page = i
      this.getClient()
    },
    /**
     * 转移客户
     */
    setOrderGiveYwUser(row) {
      const parem = {
        id: row.id
      }
      clientApi.orderGiveYwUser(parem).then(res => {
        this._showMsg('转移成功', 'success')
        this.getClient()
      })
    }
  }
}
</script>
<style lang="scss">
.el-drawer {
  width: 41rem !important;
  border-radius: 2rem 0rem 0rem 2rem;
}
.el-drawer__body {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}
.el-form-item__content {
  display: flex;
  align-items: center;
  margin-left: 0 !important;
  width: 70%;
}

.el-form-item__label {
  padding: 0;
  flex-shrink: 0;
}
</style>

<style lang="scss" scoped>
.client-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  overflow-y: auto;
  padding-bottom: 50px;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}
.btn-group {
  .deal {
    background: linear-gradient(109deg, #099cfa, #56b7f5);
  }
  .export {
    background: linear-gradient(109deg, #2fca70, #05c154);
  }
  .new {
    background: linear-gradient(109deg, #fc9940, #f37902);
  }
}

.table-operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .edit {
    cursor: pointer;
    color: #f6184c;
  }
  .follow {
    cursor: pointer;
    color: #1db8ab;
  }
}
.newClient {
  .title {
    width: 40rem;
    padding: 0 1.5rem;
    font-size: 0.94rem;
    font-weight: 400;
    color: #333333;
    line-height: 1;
  }
  .form {
    .form-list {
      display: flex;
      align-content: center;
      .list-value {
        width: 90%;
      }
    }
  }
  .clientFrom {
    padding: 0 0.8rem;
    display: flex;
    flex-wrap: wrap;
  }
  .newClientFrom {
    padding: 1.55rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .w-50 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 50%;
    }
    .w-80 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 80%;
    }
    .name {
      font-size: 0.94rem;
      flex-shrink: 0;
      text-align: right;
      width: 5.5rem;
      color: #333;
    }
    .inp {
      margin: 0 2rem 0 0.8rem;
      flex-grow: 1;
    }
    .align-start {
      align-items: flex-start;
    }
  }
  .newClientFromPadding {
    padding: 0.5rem 0.5rem;
  }
  .clientFrom {
    padding: 0 0.8rem;
    display: flex;
    flex-wrap: wrap;
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4.5rem 0 20rem;
    .btn {
      text-align: center;
      font-size: 0.94rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 2.2rem;
      width: 11.25rem;
      height: 2.2rem;
      background: linear-gradient(215deg, #1db8ab, #179d92);
      border-radius: 0.78rem;
    }
  }
}
.addTimeLine-box {
  padding: 6.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .addTimeLine {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.38rem;
    height: 2.39rem;
    background: linear-gradient(215deg, #1db8ab, #179d92);
    border-radius: 0.63rem;
    font-size: 0.83rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 1;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 3.05rem 0;
  div {
    width: 5.56rem;
    height: 2.2rem;
    text-align: center;
    line-height: 2.22rem;
    background: #e5ebeb;
    border-radius: 0.78rem;
    color: #000000;
    font-weight: 400;
    font-size: 0.94rem;
  }
  .confirm {
    background: linear-gradient(46deg, #1db8ab, #179d92);
    margin-left: 3.75rem;
    color: #ffffff;
  }
}
.ACustomer {
  .title {
    width: 40rem;
    padding: 0 1.5rem;
    font-size: 0.94rem;
    font-weight: 400;
    color: #333333;
    line-height: 1;
  }
  .form {
    .form-list {
      display: flex;
      align-content: center;
      .list-value {
        width: 100%;
      }
    }
  }

  // .w-80 {
  //   display: flex;
  //   align-items: center;
  //   margin: 15px 0;
  //   width: 80%;
  // }
  // .name {
  //   font-size: 0.94rem;
  //   flex-shrink: 0;
  //   text-align: right;
  //   width: 5.5rem;
  //   color: #333;
  // }
  // .inp {
  //   margin: 0 2rem 0 0.8rem;
  //   flex-grow: 1;
  // }
  // .align-start {
  //   align-items: flex-start;
  // }
}

.transfer {
  color: #0e9ef9;
}
</style>
